import {translate} from '../../libs/@elements/translations';

const isNonEmptyObject = ( v ) => {
    return typeof v === 'object' && v !== null
        && !Array.isArray( v )
        && (Object.keys(v).length > 0)
    ;
}

export const trans = ( key, parameters = null ) => {
    let message = translate( key );
    if( parameters !== null && isNonEmptyObject( parameters ) ) {
        for( const [k,v] of Object.entries( parameters ) ) {
            // simple primitive check
            if( v !== null && v !== '' && ['string','number', 'bigint'].includes( typeof v ) ) {
                message = message.replaceAll( '%' + k + '%', v )
            }
        }
    }
    return message;
};

export const transChoice = ( key, number, parameters = null ) => {
    // note: implemented due to the lack of symfony-ux
    let count = getSafeChoiceCount( number );
    let choiceKey = choiceKeySelect( key, count );
    let transParameters = parameters !== null && isNonEmptyObject( parameters ) ? parameters : {};
    return trans( choiceKey, { count: count,...transParameters } );
}

const choiceKeySelect = ( key, count ) => {
    // simple pluralization algo - generate own key
    if( count === 1 ) {
        return key + '__singular';
    } else {
        // for 0 and 1+
        return key + '__default';
    }
}

const getSafeChoiceCount = ( v ) => {
    // default to zero
    let c = 0;
    if( typeof v !== 'undefined' && v !== null ) {
        if( Number.isInteger( v ) ) {
            c = v;
        } else {
            let n = Number(v);
            if( !Number.isNaN( n ) ) {
                c = Number.isInteger( n ) ? n : Math.floor( n );
            }
        }
    }
    return c;
}


export default {
    install: (app, options) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$translate = trans;
        app.config.globalProperties.$translateChoice = transChoice;
    }
}