"use strict";

import { CountUp } from 'countup.js';
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";

const defaultOptions = {
    startVal: 0,
    decimal: ',',
    decimalPlaces: 0,
    separator: '.',
    useGrouping: true,
    suffix: '',
    prefix: '',
    duration: 2,
    enableScrollSpy: true,
    useEasing: true,
    smartEasingThreshold: 999999999,
    smartEasingAmount: 1000,
    scrollSpyOnce: true,
    easingFn: function (t, b, c, d) {
        return c * t / d + b;
    }
};


function getLocalizedOptions() {
    let options = defaultOptions;
    if( _config.numberFormat && _config.numberFormat.numeralDecimalMark ) {
        options.decimal = _config.numberFormat.numeralDecimalMark;
    }
    if( _config.numberFormat && _config.numberFormat.delimiter ) {
        options.separator = _config.numberFormat.delimiter;
    }
    return options;
}


export function initInScope ($scope) {

    let $counter = $scope.find('.js-counter')

    $counter.each(function () {
        let options = {
            ...getLocalizedOptions(),
            ...getPrefixedDataSet( 'counter', $(this) )
        };
        let countUpInstance = new CountUp(this, $(this).attr('data-counter-to'), options);

        countUpInstance.start();
    });

}