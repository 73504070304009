"use strict";

import {createApp} from 'vue';
import { createPinia } from 'pinia';
import translatePlugin from '../vue/plugins/translate.js';
import bookingWidget from '../vue/views/BookingWidget.vue';

export function initInScope ($scope) {
    $scope.find('.js-booking-widget').each(function () {
        let el = this;
        const pinia = createPinia();

        let vueApp = createApp({
            components: {
                bookingWidget
            }
        });
        vueApp.use(pinia);
        vueApp.use(translatePlugin);
        vueApp.mount(el);
    });
}