import { defineStore } from 'pinia';
import axios from "axios";
import { formatDate } from "../utils/utils";
import {
    dateToISOString,
    ISOStringToDate,
    localDateToUTCDate,
    UTCDateToLocalDate
} from "../../libs/@elements/date-utils";
export const useBookingDataStore = defineStore('bookingDataStore', {
    state: () => {
        return {
            currentStep: 0,
            nextButtonDisabled: true,
            reducedVersion: false,
            showDate: false,
            date: null, // holds the datepicker's selected date range [startDate, endDate]
            startDate: null, // start date, when the user selects a date range
            endDate: null, // end date, when the user selects a date range
            dateConfirmed: false, // this is set to true if the user clicks "next" on the datepicker
            loading: false, // shows the loading indicator
            locationPredefined: false,
            selectedLocation: {
                location: null, // the location object - should contain a title
                availableRanges: [], // the available date ranges for the selected location
                closedDates: [], // single days that can note be selected
                minDate: null,
                maxDate: null,
                formAction: null, // the form action for the search form contains some location details
                durationDefault: 7, // duration for the initially selected range
                durationMax: 13, // max-duration off by one,
                dateValidationError: null,

                adultMinAge: 20,
                childMinAge: 2,
                childMaxAge: 19,
            },
            showPersonAmount: false,
            adults: 1,
            children: 0,
            childrenAges: [],
            childDefaultAge: 10,
            eventTracking: false
        }
    },
    getters: {
        // returns the formatted startDate
        formattedStartDate() {
            return formatDate(this.startDate);
        },
        // returns the formatted endDate
        formattedEndDate() {
            return formatDate(this.endDate);
        }
    },
    actions: {
        async requestLocationDetails(detailURL){
            if (!detailURL) {
                return;
            }
            try {
                this.loading = true;

                let dates = this.date;

                let currentStart = null;
                let currentEnd = null;

                let params = {};

                // pass currently selected date range into detail url to trigger backend validation
                // this is important when the user changes the location after selecting a date range
                if( dates.length === 2 ) {
                    [currentStart, currentEnd] = dates;
                    // if the date is not already an ISOString, convert it
                    if (currentStart instanceof Date) {
                        currentStart = dateToISOString(localDateToUTCDate(currentStart), false);
                    }
                    if (currentEnd instanceof Date) {
                        currentEnd = dateToISOString(localDateToUTCDate(currentEnd), false);
                    }
                    params = {from: currentStart, till: currentEnd};
                }
                const {data} = await axios.get(detailURL, {params: params});
                this.loading = false;

                this.selectedLocation.availableRanges = data.ranges;
                this.selectedLocation.closedDates = data.closedDates;
                this.selectedLocation.minDate = data.min;
                this.selectedLocation.maxDate = data.max;
                this.selectedLocation.maxRange = data.durationMax;
                this.selectedLocation.durationDefault = data.durationDefault;
                if( data.adultMinAge ) {
                    this.selectedLocation.adultMinAge = data.adultMinAge;
                    if( data.childMinAge && data.childMaxAge ) {
                        this.selectedLocation.childMinAge = data.childMinAge;
                        this.selectedLocation.childMaxAge = data.childMaxAge;
                    }
                }

                let defaultStart = null;
                let defaultEnd = null;
                if( Array.isArray( data.defaultDates ) && data.defaultDates.length === 2 ) {
                    [defaultStart, defaultEnd] = data.defaultDates;
                } else {
                    // note: data should always be available - fallback to client-side calculation
                    let defaultDates = this.getDefaultDatesFromConstraints();
                    defaultStart = dateToISOString(localDateToUTCDate(defaultDates[0]));
                    defaultEnd = dateToISOString(localDateToUTCDate(defaultDates[1]));
                }
                let validatedStart = null;
                let validatedEnd = null;
                let validationFailed = false;
                // check, if backend could calculate a valid date range
                if( Array.isArray( data.validateDates ) ) {
                    if( data.validateDates.length === 2 ) {
                        // may be same as current selection or corrected range (e.g. closed on pickupStart -> shifted 1 day forward)
                        [validatedStart, validatedEnd] = data.validateDates;
                    } else {
                        // no valid / corrected date could be calculated - most likely out of seasonal bounds
                        validationFailed = true;
                    }
                }

                // reset error before proceeding
                this.selectedLocation.dateValidationError = null;
                if( currentStart && currentEnd ) {
                    // check if the previously selected date range is still possible (this can happen if the user changes the location)
                    let possibleStartDate = validatedStart ?? defaultStart;
                    let possibleEndDate = validatedEnd ?? defaultEnd;
                    // verify if validated dates differ from server-side result (should be ISO formatted strings)
                    if( possibleStartDate !== currentStart || possibleEndDate !== currentEnd ) {
                        if( !this.dateConfirmed ) {
                            // always update from server-side results if date has not been confirmed yet
                            this.date = [ UTCDateToLocalDate(ISOStringToDate(possibleStartDate)), UTCDateToLocalDate(ISOStringToDate(possibleEndDate)) ];
                        } else
                        if( validationFailed ) {
                            // previously confirmed date-range not available at all for selected location
                            // show error on next button (should be date confirm)
                            this.nextButtonDisabled = true;
                            this.selectedLocation.dateValidationError = 'rent_site.location.booking-widget.error.rental-period.invalid-for-location';
                        } else {
                            // previously confirmed date-range only partially available for selected location
                            // may automatically replace current selection but user may not notice this change, therefore handle the same as a complete failure
                            // show error on next button (should be date confirm)
                            this.nextButtonDisabled = true;
                            this.selectedLocation.dateValidationError = 'rent_site.location.booking-widget.error.rental-period.invalid-for-location';
                        }
                    }

                } else {
                    // update from server-side with default-range if no dates have been selected yet
                    this.date = [ UTCDateToLocalDate(ISOStringToDate(defaultStart)), UTCDateToLocalDate(ISOStringToDate(defaultEnd)) ];
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        prefillDates() {
            // initial date: minDate to minDate + durationDefault (but only if it's not prefilled)
            if (!this.date?.length) {
                this.updateToDefaultDates();
            } else {
                // if date is prefilled, convert to the correct timezone
                this.date = [UTCDateToLocalDate(ISOStringToDate(this.date[0])), UTCDateToLocalDate(ISOStringToDate(this.date[1]))];
            }
            // start and end date are set in date watcher in CalendarView.vue
        },

        updateToDefaultDates() {
            let [start, end] = this.getDefaultDatesFromConstraints();
            this.date = [start, end];
        },

        getDefaultDatesFromConstraints() {
            let fromDate = UTCDateToLocalDate(ISOStringToDate(this.selectedLocation.minDate));
            // note: should be iso-string instead of date-object ?
            let minDate = UTCDateToLocalDate(ISOStringToDate(this.selectedLocation.minDate));
            let tillDate = minDate.setDate(minDate.getDate() + this.selectedLocation.durationDefault);
            return [ fromDate, tillDate ];
        }
    }
})
