import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group number-spinner mb-0" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["disabled", "aria-controls"]
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = ["name", "value", "min", "max", "step", "required", "disabled", "readonly", "placeholder", "id"]
const _hoisted_6 = ["disabled", "aria-controls"]
const _hoisted_7 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        disabled: _ctx.disabled || _ctx.readonly || !_ctx.decreasable,
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.decrease && _ctx.decrease(...args)), ["prevent"])),
        class: "btn btn-primary btn--show-disabled-state btn-number-spinner",
        type: "button",
        "aria-controls": _ctx.name
      }, [
        _createElementVNode("span", {
          "aria-label": _ctx.$translate('rent_site.form.amount-decrement'),
          class: "icon icon-minus"
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3),
      _createElementVNode("input", _mergeProps({
        class: ["text-center number-spinner__input form-control", {'is-invalid': _ctx.errors.length}],
        ref: "input"
      }, _ctx.attrs, {
        type: "number",
        name: _ctx.name,
        value: _ctx.modelValue,
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step,
        required: _ctx.required,
        disabled: _ctx.disabled || (!_ctx.decreasable && !_ctx.increasable),
        readonly: _ctx.readonly || !_ctx.inputtable,
        placeholder: _ctx.placeholder,
        id: _ctx.id,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
        onPaste: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.paste && _ctx.paste(...args)))
      }), null, 16, _hoisted_5),
      _createElementVNode("button", {
        disabled: _ctx.disabled || _ctx.readonly || !_ctx.increasable,
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.increase && _ctx.increase(...args)), ["prevent"])),
        class: "btn btn-primary btn--show-disabled-state btn-number-spinner",
        type: "button",
        "aria-controls": _ctx.name
      }, [
        _createElementVNode("span", {
          "aria-label": _ctx.$translate('rent_site.form.amount-increment'),
          class: "icon icon-plus"
        }, null, 8, _hoisted_7)
      ], 8, _hoisted_6)
    ])
  ]))
}