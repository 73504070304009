<template>
    <div class="loading-spinner loading-spinner--shop" :aria-label="$translate('rent_site.content.loading')" v-if="loading">
        <div class="loading-spinner__item loading-spinner__item--1"></div>
        <div class="loading-spinner__item loading-spinner__item--2"></div>
        <div class="loading-spinner__item loading-spinner__item--3"></div>
        <div class="visually-hidden">{{ $translate('rent_site.content.loading') }}</div>
    </div>
</template>

<script>
// @ts-ignore
import { defineComponent } from "vue";

export default defineComponent({
    name: 'LoadingSpinner',
    props: {
        loading: {
            type: Boolean,
            required: true,
        }
    },
    setup() {
        return {
            translate
        };
    }
});
</script>
