
import {ref, watch, defineComponent} from 'vue';
import LoadingSpinner from '../../components/LoadingSpinner.vue';
import {lockBodyOverflow, unlockBodyOverflow} from '../../utils/utils';


export default defineComponent({
    components: {
        // ModalHeader,
        // ModalFooter,
        LoadingSpinner
    },
    props: {
        nextButtonDisabled: {
            type: Boolean,
            required: false,
            default: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        bodyModifier: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ["close"],
    setup(props, {emit}) {
        const showModal = ref(false);
        const modalRef = ref(null);

        const close = () => {
            showModal.value = false;
            unlockBodyOverflow();
            emit('close');
        };

        const show = () => {
            showModal.value = true;
            lockBodyOverflow();
        };

        return {
            modalRef,
            showModal,
            close,
            show,
            emit
        };
    },
});
