import * as dayjs from 'dayjs';
import {trackToGTM} from "../../scripts/tracking";

export const locales = {
    'en': 'en-US',
    'de': 'de-DE',
    'fr': 'fr-FR',
    'it': 'it-IT'
};
export function formatDate(dateString, locale) {
//     const date = new Date(dateString);
//
//     return dayjs(date).format(format);
     const date = new Date(dateString);
     if (!locale) {
         locale = locales[window._config.lang];
     }

     // return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
     return new Intl.DateTimeFormat(locale, {dateStyle: 'medium'}).format(date);
}

export function lockBodyOverflow() {
    if (document.body.scrollHeight > window.innerHeight) {
        document.body.style.paddingRight = getScrollbarWidth() + 'px';
        document.body.style.overflow = 'hidden';
    }
}

export function unlockBodyOverflow() {
    document.body.style.paddingRight = '';
    document.body.style.removeProperty('overflow');
}

function getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}

export function trackSearch( term, trigger ) {
    if( typeof term === 'string' && term.length > 2 ) {
        // let trackerType = searchBox.data('external-query-tracker');
        // if( trackerType === 'gtm' ) {
        let payload = {
            'event': 'bookingSearchQuery',
            'query': term, 'trigger': trigger
        };
        trackToGTM( payload, false );
        // }
    }
}